import * as Types from '../Constants/AllConstants'

// get all categories

export const getBannersBySectionReducer = (
    state = {
        banners:[],
    },
    action
) => {
    switch (action.type) {
        case Types.BANNER_GET_BY_SECTION_REQUEST:
            return { loading: true };
        case Types.BANNER_GET_BY_SECTION_SUCCESS:
            return { loading: false, banners: action.payload};
        case Types.BANNER_GET_BY_SECTION_FAIL:
            return { loading: false, error: action.payload};
        case Types.BANNER_GET_BY_SECTION_RESET:
            return { banners: [] };
        default:
            return state;
    }
};

// create banner reducer
export const createBannerReducer = (state = { banner: {} }, action) => {
    switch (action.type) {
        case Types.BANNER_CREATE_REQUEST:
            return { loading: true};
        case Types.BANNER_CREATE_SUCCESS:
            return { loading: false, success: true, banner: action.payload };     
        case Types.BANNER_CREATE_FAIL:
            return { loading: false, error: action.payload }; 
        case Types.BANNER_CREATE_RESET:
            return { banner: {} };       
        default:
            return state;
    }
}

// UPDATE banner by id reducer
export const updateBannerReducer = (state = { banner: {} }, action) => {
    switch (action.type) {
        case Types.BANNER_UPDATE_REQUEST:
            return { loading: true};
        case Types.BANNER_UPDATE_SUCCESS:
            return { loading: false, success: true};
        case Types.BANNER_UPDATE_FAIL:
            return { loading: false, error: action.payload};
        case Types.BANNER_UPDATE_RESET:
            return {};
        default:
            return state;
    }
}

// DELETE banner by id reducer
export const deleteBannerReducer = (state = { banner: {} }, action) => {
    switch (action.type) {
        case Types.BANNER_DELETE_REQUEST:
            return { loading: true};
        case Types.BANNER_DELETE_SUCCESS:
            return { loading: false, success: true};
        case Types.BANNER_DELETE_FAIL:
            return { loading: false, error: action.payload};
        case Types.BANNER_DELETE_RESET:
            return {};
        default:
            return state;
    }
}  

