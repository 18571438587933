//ADMIN CONSTANTS
export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';
export const ADMIN_LOGIN_RESET = 'ADMIN_LOGIN_RESET';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';

// USER CONSTANTS
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET';
export const USER_LOGOUT = 'USER_LOGOUT';
 
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';
export const PROFILE_UPDATE_RESET = 'PROFILE_UPDATE_RESET';

export const ADDRESS_UPDATE_REQUEST = 'ADDRESS_UPDATE_REQUEST';
export const ADDRESS_UPDATE_SUCCESS = 'ADDRESS_UPDATE_SUCCESS';
export const ADDRESS_UPDATE_FAIL = 'ADDRESS_UPDATE_FAIL';
export const ADDRESS_UPDATE_RESET = 'ADDRESS_UPDATE_RESET';

export const ADDRESS_DELETE_BY_ID_REQUEST = 'ADDRESS_DELETE_BY_ID_REQUEST';
export const ADDRESS_DELETE_BY_ID_SUCCESS = 'ADDRESS_DELETE_BY_ID_SUCCESS';
export const ADDRESS_DELETE_BY_ID_FAIL = 'ADDRESS_DELETE_BY_ID_FAIL';
export const ADDRESS_DELETE_BY_ID_RESET = 'ADDRESS_DELETE_BY_ID_RESET';

export const ADDRESS_UPDATE_BY_ID_REQUEST = 'ADDRESS_UPDATE_BY_ID_REQUEST';
export const ADDRESS_UPDATE_BY_ID_SUCCESS = 'ADDRESS_UPDATE_BY_ID_SUCCESS';
export const ADDRESS_UPDATE_BY_ID_FAIL = 'ADDRESS_UPDATE_BY_ID_FAIL';
export const ADDRESS_UPDATE_BY_ID_RESET = 'ADDRESS_UPDATE_BY_ID_RESET';

export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAIL = 'PASSWORD_CHANGE_FAIL';
export const PASSWORD_CHANGE_RESET = 'PASSWORD_CHANGE_RESET';

export const ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST';
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS';
export const ACCOUNT_DELETE_FAIL = 'ACCOUNT_DELETE_FAIL';
export const ACCOUNT_DELETE_RESET = 'ACCOUNT_DELETE_RESET';

export const USER_GET_ALL_REQUEST = 'USER_GET_ALL_REQUEST';
export const USER_GET_ALL_SUCCESS = 'USER_GET_ALL_SUCCESS';
export const USER_GET_ALL_FAIL = 'USER_GET_ALL_FAIL';
export const USER_GET_ALL_RESET = 'USER_GET_ALL_RESET';

export const USER_GET_BY_ID_REQUEST = 'USER_GET_BY_ID_REQUEST';
export const USER_GET_BY_ID_SUCCESS = 'USER_GET_BY_ID_SUCCESS';
export const USER_GET_BY_ID_FAIL = 'USER_GET_BY_ID_FAIL';
export const USER_GET_BY_ID_RESET = 'USER_GET_BY_ID_RESET';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_DELETE_RESET = 'USER_DELETE_RESET';

// CATEGORY CONSTANTS
export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL';
export const CATEGORY_LIST_RESET = 'CATEGORY_LIST_RESET';

export const CATEGORY_GET_BY_ID_REQUEST = 'CATEGORY_GET_BY_ID_REQUEST';
export const CATEGORY_GET_BY_ID_SUCCESS = 'CATEGORY_GET_BY_ID_SUCCESS';
export const CATEGORY_GET_BY_ID_FAIL = 'CATEGORY_GET_BY_ID_FAIL';
export const CATEGORY_GET_BY_ID_RESET = 'CATEGORY_GET_BY_ID_RESET';

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL';
export const CATEGORY_UPDATE_RESET = 'CATEGORY_UPDATE_RESET';

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL';
export const CATEGORY_DELETE_RESET = 'CATEGORY_DELETE_RESET';

export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST';
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS';
export const CATEGORY_CREATE_FAIL = 'CATEGORY_CREATE_FAIL';
export const CATEGORY_CREATE_RESET = 'CATEGORY_CREATE_RESET';


// BANNER CONSTANTS

export const BANNER_GET_BY_SECTION_REQUEST = 'BANNER_GET_BY_SECTION_REQUEST';
export const BANNER_GET_BY_SECTION_SUCCESS = 'BANNER_GET_BY_SECTION_SUCCESS';
export const BANNER_GET_BY_SECTION_FAIL = 'BANNER_GET_BY_SECTION_FAIL';
export const BANNER_GET_BY_SECTION_RESET = 'BANNER_GET_BY_SECTION_RESET';

export const BANNER_UPDATE_REQUEST = 'BANNER_UPDATE_REQUEST';
export const BANNER_UPDATE_SUCCESS = 'BANNER_UPDATE_SUCCESS';
export const BANNER_UPDATE_FAIL = 'BANNER_UPDATE_FAIL';
export const BANNER_UPDATE_RESET = 'BANNER_UPDATE_RESET';

export const BANNER_DELETE_REQUEST = 'BANNER_DELETE_REQUEST';
export const BANNER_DELETE_SUCCESS = 'BANNER_DELETE_SUCCESS';
export const BANNER_DELETE_FAIL = 'BANNER_DELETE_FAIL';
export const BANNER_DELETE_RESET = 'BANNER_DELETE_RESET';

export const BANNER_CREATE_REQUEST = 'BANNER_CREATE_REQUEST';
export const BANNER_CREATE_SUCCESS = 'BANNER_CREATE_SUCCESS';
export const BANNER_CREATE_FAIL = 'BANNER_CREATE_FAIL';
export const BANNER_CREATE_RESET = 'BANNER_CREATE_RESET';

// TORNEO CONSTANTS 

export const TORNEO_LIST_REQUEST = 'TORNEO_LIST_REQUEST';
export const TORNEO_LIST_SUCCESS = 'TORNEO_LIST_SUCCESS';
export const TORNEO_LIST_FAIL = 'TORNEO_LIST_FAIL';
export const TORNEO_LIST_RESET = 'TORNEO_LIST_RESET';

export const TORNEO_CREATE_REQUEST = 'TORNEO_CREATE_REQUEST';
export const TORNEO_CREATE_SUCCESS = 'TORNEO_CREATE_SUCCESS';
export const TORNEO_CREATE_FAIL = 'TORNEO_CREATE_FAIL';
export const TORNEO_CREATE_RESET = 'TORNEO_CREATE_RESET';

export const TORNEO_GET_BY_ID_REQUEST = 'TORNEO_GET_BY_ID_REQUEST';
export const TORNEO_GET_BY_ID_SUCCESS = 'TORNEO_GET_BY_ID_SUCCESS';
export const TORNEO_GET_BY_ID_FAIL = 'TORNEO_GET_BY_ID_FAIL';
export const TORNEO_GET_BY_ID_RESET = 'TORNEO_GET_BY_ID_RESET';

export const TORNEO_UPDATE_REQUEST = 'TORNEO_UPDATE_REQUEST';
export const TORNEO_UPDATE_SUCCESS = 'TORNEO_UPDATE_SUCCESS';
export const TORNEO_UPDATE_FAIL = 'TORNEO_UPDATE_FAIL';
export const TORNEO_UPDATE_RESET = 'TORNEO_UPDATE_RESET';

export const TORNEO_DELETE_REQUEST = 'TORNEO_DELETE_REQUEST';
export const TORNEO_DELETE_SUCCESS = 'TORNEO_DELETE_SUCCESS';
export const TORNEO_DELETE_FAIL = 'TORNEO_DELETE_FAIL';
export const TORNEO_DELETE_RESET = 'TORNEO_DELETE_RESET';

// PRODUCT CONSTANTS
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';
export const PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const GET_PRODUCT_RESET = 'GET_PRODUCT_RESET';

export const TAGS_PRODUCT = 'TAGS_PRODUCT';
export const TAGS_PRODUCT_FAIL = 'TAGS_PRODUCT_FAIL';
export const TAGS_PRODUCT_RESET = 'TAGS_PRODUCT_RESET';

export const PRODUCT_GET_ALL_REQUEST = 'PRODUCT_GET_ALL_REQUEST';
export const PRODUCT_GET_ALL_SUCCESS = 'PRODUCT_GET_ALL_SUCCESS';
export const PRODUCT_GET_ALL_FAIL = 'PRODUCT_GET_ALL_FAIL';
export const PRODUCT_GET_ALL_RESET = 'PRODUCT_GET_ALL_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_RESET = 'PRODUCT_DELETE_RESET';

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL';
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET';

// OFFERS CONSTANTS

export const OFFER_CREATE_REQUEST = 'OFFER_CREATE_REQUEST';
export const OFFER_CREATE_SUCCESS = 'OFFER_CREATE_SUCCESS';
export const OFFER_CREATE_FAIL = 'OFFER_CREATE_FAIL';
export const OFFER_CREATE_RESET = 'OFFER_CREATE_RESET';

export const OFFER_LIST_REQUEST = 'OFFER_LIST_REQUEST';
export const OFFER_LIST_SUCCESS = 'OFFER_LIST_SUCCESS';
export const OFFER_LIST_FAIL = 'OFFER_LIST_FAIL';
export const OFFER_LIST_RESET = 'OFFER_LIST_RESET';

export const OFFER_DELETE_REQUEST = 'OFFER_DELETE_REQUEST';
export const OFFER_DELETE_SUCCESS = 'OFFER_DELETE_SUCCESS';
export const OFFER_DELETE_FAIL = 'OFFER_DELETE_FAIL';
export const OFFER_DELETE_RESET = 'OFFER_DELETE_RESET';

// CART CONSTANTS
export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_RESET = 'CART_RESET';

// ORDER CONSTANTS
export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET';

export const ORDER_GET_ALL_REQUEST = 'ORDER_GET_ALL_REQUEST';
export const ORDER_GET_ALL_SUCCESS = 'ORDER_GET_ALL_SUCCESS';
export const ORDER_GET_ALL_FAIL = 'ORDER_GET_ALL_FAIL';
export const ORDER_GET_ALL_RESET = 'ORDER_GET_ALL_RESET';

export const ORDER_GET_ALL_ADMIN_REQUEST = 'ORDER_GET_ALL_ADMIN_REQUEST';
export const ORDER_GET_ALL_ADMIN_SUCCESS = 'ORDER_GET_ALL_ADMIN_SUCCESS';
export const ORDER_GET_ALL_ADMIN_FAIL = 'ORDER_GET_ALL_ADMIN_FAIL';
export const ORDER_GET_ALL_ADMIN_RESET = 'ORDER_GET_ALL_ADMIN_RESET';

export const ORDER_GET_BY_ID_REQUEST = 'ORDER_GET_BY_ID_REQUEST';
export const ORDER_GET_BY_ID_SUCCESS = 'ORDER_GET_BY_ID_SUCCESS';
export const ORDER_GET_BY_ID_FAIL = 'ORDER_GET_BY_ID_FAIL';
export const ORDER_GET_BY_ID_RESET = 'ORDER_GET_BY_ID_RESET';

export const ORDER_GET_BY_ID_ADMINPANEL_REQUEST = 'ORDER_GET_BY_ID_ADMINPANEL_REQUEST';
export const ORDER_GET_BY_ID_ADMINPANEL_SUCCESS = 'ORDER_GET_BY_ID_ADMINPANEL_SUCCESS';
export const ORDER_GET_BY_ID_ADMINPANEL_FAIL = 'ORDER_GET_BY_ID_ADMINPANEL_FAIL';
export const ORDER_GET_BY_ID_ADMINPANEL_RESET = 'ORDER_GET_BY_ID_ADMINPANEL_RESET';

export const ORDER_DELETE = 'ORDER_DELETE';
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL';
export const ORDER_DELETE_RESET = 'ORDER_DELETE_RESET';

export const ORDER_DELETE_ALL_REQUEST = 'ORDER_DELETE_ALL_REQUEST';
export const ORDER_DELETE_ALL_SUCCESS = 'ORDER_DELETE_ALL_SUCCESS';
export const ORDER_DELETE_ALL_FAIL = 'ORDER_DELETE_ALL_FAIL';
export const ORDER_DELETE_ALL_RESET = 'ORDER_DELETE_ALL_RESET';

export const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST';
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS';
export const ORDER_UPDATE_FAIL = 'ORDER_UPDATE_FAIL';
export const ORDER_UPDATE_RESET = 'ORDER_UPDATE_RESET';

export const STRIPE = 'STRIPE';
export const STRIPE_ERROR = 'STRIPE_ERROR';
export const STRIPE_RESET = 'STRIPE_RESET';

export const STRIPE_INSCRIPTION = 'STRIPE_INSCRIPTION';
export const STRIPE_INSCRIPTION_ERROR = 'STRIPE_INSCRIPTION_ERROR';
export const STRIPE_INSCRIPTION_RESET = 'STRIPE_INSCRIPTION_RESET';